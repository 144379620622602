import React, { useState } from 'react';
import PropTypes from "prop-types";

function Card(props) {
  const article = useState(props.article)[0]

  const image = () => {
    return (
      <a className="image-mit-link" href={article.link} target="_blank" rel="noopener noreferrer">
        <img alt="Artikel" src={article.image}></img>
      </a>
    );
  }

  const format_date = () => {
    let date = Date.parse(article.publication_date)
    return new Intl.DateTimeFormat("de-CH", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(date);
  }

  const publication_date = () => {
    return <div className="publication_date">{format_date()}</div>;
  }

  const remove = () => {
    props.removeArticle(article.id);
  }

  const formatted_source_code = () => {
    switch (article.source_code) {
      case "20min_finance":
        return "20 Finance";
      case "20min_outland":
        return "20 Ausland";
      case "20min_esport":
        return "20 Esport";
      case "20min_swiss":
        return "20 Swiss";
      case "golem":
        return "Golem";
      case "linux_magazin":
        return "Linux Magazin";
      default:
        return article.source_code;
    }
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="flex">
          <div>
            <div className="flex">
              <div className="source_code">{formatted_source_code()}</div>
              {publication_date()}
            </div>
            <div className="title">{article.title}</div>
            {article.text}
          </div>
          {image()}
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={remove}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Card;

Card.propTypes = {
  article: PropTypes.object.isRequired,
  removeArticle: PropTypes.func.isRequired
}

