import React, { useState, useEffect } from "react";
import Card from './Card'
import FilterPanel from './FilterPanel'
import './App.css';
import axios from 'axios'

function App() {
  const [articles, setArticles] = useState([])
  const [count, setCount] = useState(50)
  const [source, setSource] = useState("all")

  useEffect(() => {
    let source_param, count_param
    let url = "https://news.elu.noip.me/api/v1/articles.json";

    if (source === "all") {
      source_param = undefined
    } else {
      source_param = source
    }

    if (count === "all") {
      count_param = undefined
    } else {
      count_param = count
    }

    axios(url, { params: { "count": count_param, "source": source_param }})
      .then(response => {
        setArticles(response.data);
      })
      .catch(function(error) {
        console.log("Sorry!!!");
      });
    },[count, source]);


  const removeArticle = (articleId) => {
    setArticles(articles.filter(article => {
      return article.id !== articleId
    }))
  }

  return (
    <div className="App">
      <div className="container-fluid">
        <FilterPanel setCount={setCount} setSource={setSource} count={articles.length}></FilterPanel>
        {articles.map(article => (
          <Card key={article.id} article={article} removeArticle={removeArticle}></Card>
        ))}
      </div>
    </div>
  );
}

export default App;
