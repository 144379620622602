import React from "react";
import PropTypes from "prop-types";

function FilterPanel(props) {
  const build_count_options = () => {
    let options = [10, 25, 50, 100, 250, 500, 1000]
    let method = options.map((item, i) => {
      return {val: item, text: item}
    })

    let alle =  {val: "all", text: "Alle"}
    method.push(alle)
    return build_options(method)
  }

  const build_source_options = () => {
    let options = [
      { val: "all", text: "Alle" },
      { val: "20min_finance", text: "20 Finance" },
      { val: "20min_outland", text: "20 Ausland" },
      { val: "20min_swiss", text: "20 Swiss" },
      { val: "20min_esport", text: "20 Esport" },
      { val: "golem", text: "Golem" },
      { val: "linux_magazin", text: "Linux Magazin" }
    ];

    return build_options(options)
  }

  const build_options = (options) => {
    let html = options.map((item, i) => {
      if (item.val === 50) {
      return (
        <option key={i} value={item.val} selected>
          {item.text}
        </option>
      );
      } else {
      return (
        <option key={i} value={item.val}>
          {item.text}
        </option>
      );
      }
    });

    return html
  }

  return (
    <div className="card">
      <div className="card-body flex">
        <form className="form-inline">
          <div className="form-group mb-2">
            <label htmlFor="count">Anzahl:</label>
            <select
              id="count"
              onChange={(event) => props.setCount(event.target.value)}
            >
              {build_count_options()}
            </select>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="source">Source:</label>
            <select
              id="source"
              onChange={(event) => props.setSource(event.target.value)}
            >
              {build_source_options()}
            </select>
          </div>
        </form>
        <div className="count-articles">
          <label htmlFor="count-articles">Anzahl:</label>
          {props.count}
        </div>
      </div>
    </div>
  );
}

export default FilterPanel;

FilterPanel.propTypes = {
  setCount: PropTypes.func.isRequired,
  setSource: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired
};

